import React, { Fragment } from 'react';
import './DataHasilPasien.css';
import { makeStyles, lighten } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import LoadData from '../../../services/rujukan';
import { formatRupiah, formatPercent } from '../../../@jumbo/utils/formatHelper';
import {
  setActiveRujukanPagination,
  setRujukanLimit,
  setRujukanStartDate,
  setRujukanEndDate,
  setRujukanKeyword,
} from '../../../redux/actions';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'namapemeriksa',
    numeric: false,
    disablePadding: true,
    label: 'Nama Pemeriksa',
  },
  { id: 'harga', numeric: true, disablePadding: false, label: 'Harga' },
  {
    id: 'diskonpersen',
    numeric: true,
    disablePadding: false,
    label: 'Diskon Persen',
  },
  {
    id: 'diskonrupiah',
    numeric: true,
    disablePadding: false,
    label: 'Diskon Rupiah',
  },
  { id: 'netti', numeric: true, disablePadding: false, label: 'Netto' },
];

function HeaderTable(props) {
  const { onSelectAllClick, numSelected, rowCount, countMatch } = props;

  return (
    <TableHead className="table-head-hasil-rujukan">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            className="checkTab"
            indeterminate={rowCount > 0 && countMatch < 10}
            checked={rowCount > 0 && countMatch === 10}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

HeaderTable.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const TableDetailPasien = ({
  id,
  setSelectedPrice,
  selectedPrice,
  setSelectedData,
  selectedData = [],
  intialSelected = [],
  id_rujukan = null,
  showOnly = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rujukanData, activePaginate, pagination, limit, keyword } = useSelector(({ rujukan }) => rujukan);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // React.useEffect(() => {
  //   dispatch(LoadData.getPemerikasaanList(id, id_rujukan));
  // }, [dispatch, id, activePaginate, limit, keyword, id_rujukan]);

  React.useEffect(() => {
    if (intialSelected.length > 0) {
      const id = intialSelected.map(item => item.kodetest);
      setSelected(id);
      setSelectedData(intialSelected);
      setSelectedPrice(intialSelected.map(item => item.harga));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(intialSelected)]);

  const HandleRowPagination = (event, current_page) => {
    dispatch(setRujukanLimit(event.target.value));
  };

  const HandlePagination = (event, current_page) => {
    dispatch(setActiveRujukanPagination(current_page));
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rujukanData.map(n => n.kodetest);
      const newSelectedPrices = rujukanData.map(n => n.harga);
      setSelectedPrice([...selectedPrice, ...newSelectedPrices]);
      setSelected([...selected, ...newSelecteds]);
      setSelectedData([...selectedData, ...rujukanData]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.kodetest);
    let newSelected = [];
    let newSelectedPrice = [];
    let newSelectedData = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.kodetest);
      newSelectedPrice = newSelectedPrice.concat(selectedPrice, row.harga);
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedPrice = newSelectedPrice.concat(selectedPrice.slice(1));
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedPrice = newSelectedPrice.concat(selectedPrice.slice(0, -1));
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newSelectedPrice = newSelectedPrice.concat(
        selectedPrice.slice(0, selectedIndex),
        selectedPrice.slice(selectedIndex + 1),
      );
      newSelectedData = newSelectedData.concat(selectedData.slice(0, selectedIndex), selectedData.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    setSelectedPrice(newSelectedPrice);
    setSelectedData(newSelectedData);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <div className="table-wrap-add-pasien">
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <HeaderTable
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={!showOnly ? handleSelectAllClick : () => {}}
              rowCount={intialSelected.length}
              activePaginate={activePaginate}
              countMatch={intialSelected.filter(data => selected.includes(data.kodetest)).length}
            />

            <TableBody>
              {stableSort(intialSelected, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.kodetest);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow className={'checkTab'} hover role="checkbox" tabIndex={-1} key={row.kodetest}>
                      <TableCell padding="checkbox" onClick={!showOnly ? event => handleClick(event, row) : () => {}}>
                        <Checkbox
                          className="checkTab"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled={showOnly}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                        {row.nama_test}
                      </TableCell>
                      <TableCell align="left">{formatRupiah(row.harga, 'Rp. ')}</TableCell>
                      <TableCell align="left">{formatPercent(row.diskon_persen)}</TableCell>
                      <TableCell align="left">{formatRupiah(row.diskon_rupiah, 'Rp. ')}</TableCell>
                      <TableCell align="left">{formatRupiah(row.netto, 'Rp. ')}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={pagination.total}
          rowsPerPage={limit}
          page={activePaginate - 1}
          onPageChange={(e, c) => HandlePagination(e, c + 1)}
          onRowsPerPageChange={(e, c) => HandleRowPagination(e, c)}
        /> */}
      </div>
    </Fragment>
  );
};

export default TableDetailPasien;
