import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SamplePage from './Pages/SamplePage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Home from './Pages/Home';
import HasilPasiens from './Pages/HasilPasien';
import DataHasilRujukan from './Pages/DataHasilPasien';
import ChangePass from './Pages/ChangePassword';
import DetailInformasi from './Pages/Home/DetailInformasi';
import AddPasienRujuk from './Pages/DataHasilPasien/AddPasienRujuk';
import DetailPasienRujukan from './Pages/DataHasilPasien/DetailPasienRujukan';
import EditPasienRujukan from './Pages/DataHasilPasien/EditPasienRujukan';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/home'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/home" component={Home} />
        <RestrictedRoute path="/detail-informasi" component={DetailInformasi} />
        <RestrictedRoute path="/hasil-pasien" component={HasilPasiens} />
        <RestrictedRoute path="/data-pasien-rujukan" component={DataHasilRujukan} />
        <RestrictedRoute path="/detail-pasien-rujukan/:id" component={DetailPasienRujukan} />
        <RestrictedRoute path="/edit-pasien-rujukan/:id" component={EditPasienRujukan} />
        <RestrictedRoute path="/change-password" component={ChangePass} />
        <RestrictedRoute path="/add-pasien-rujukan" component={AddPasienRujuk} />
        <Route path="/sample-page" component={SamplePage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
