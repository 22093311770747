import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';

const InfoUser = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Fragment>
      <div className="wrap-info-user">
        <div className="content-wrap-user">
          <p className="title-info-user">Qr Code</p>
          <div className="barcode-wrap">
            <div style={{ height: 'auto', margin: '0 auto', maxWidth: 130, width: '100%' }}>
              <QRCode
                size={356}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`${authUser?.id}-${authUser?.nama}`}
                viewBox={`0 0 356 356`}
              />
            </div>
          </div>
          <div className="info-user-wrap">
            <p style={{ fontWeight: 'bold' }}>Name</p>
            <p>{authUser?.nama}</p>
            <p style={{ fontWeight: 'bold' }}>Alamat</p>
            <p>{authUser?.alamat}.</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InfoUser;
