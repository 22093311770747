import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import url from '../../../@jumbo/constants/UrlConstant';
import instance from './config';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password, role }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        let endpoin;
        if (role === 1) {
          endpoin = url.personalAuth.login;
        } else if (role === 2) {
          endpoin = url.doktorAuth.login;
        } else {
          endpoin = url.instansiAuth.login;
        }
        instance
          .post(endpoin, {
            id: email,
            password: password,
          })
          .then(({ data, status }) => {
            if (status === 201) {
              localStorage.setItem('accessToken', data.access_token);
              localStorage.setItem('mainRole', role);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
              dispatch(fetchSuccess());
              /*active this line if getUserApi is ready
              
              */
              dispatch(JWTAuth.getAuthUser(true, data.access_token));
            } else {
              if (data.success === false) {
                let errorMessage = '';
                if (data.message?.id) {
                  errorMessage = data.message?.id[0];
                } else {
                  errorMessage = data.message?.password[0];
                }
                dispatch(fetchError(errorMessage));
              }
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      const role = localStorage.getItem('mainRole');
      let endpoin;
      if (role === 1) {
        endpoin = url.personalAuth.logout;
      } else if (role === 2) {
        endpoin = url.doktorAuth.logout;
      } else {
        endpoin = url.instansiAuth.logout;
      }
      instance
        .post(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            localStorage.clear();
            dispatch(setAuthUser(null));
            // window.location.reload();
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('accessToken');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      const role = localStorage.getItem('mainRole');
      let endpoin;
      if (role === '1') {
        endpoin = url.personalAuth.me;
      } else if (role === '2') {
        endpoin = url.doktorAuth.me;
      } else {
        endpoin = url.instansiAuth.me;
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 201) {
            dispatch(fetchSuccess());
            data.user.role = data.role;
            dispatch(setAuthUser(data.user));
          } else {
            dispatch(setAuthUser(null));
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(setAuthUser(null));
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
