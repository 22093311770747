import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { getRujukanList, setActiveRujukanPagination, setRujukanPagination, setRujukanListTitle } from '../../redux/actions';

const rujukanLoad = {
  getRujukanList: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword, startDate, endDate, limit } = getState().rujukan;
      dispatch(fetchStart());
      let endpoin;
      if (keyword) {
        dispatch(setActiveRujukanPagination(1));
        endpoin = `${url.rujukan.listData}?keyword=${keyword}`;
      } else {
        if (startDate !== '' && endDate !== '') {
          endpoin = `${url.rujukan.listData}?page=${activePaginate}&start_date=${startDate}&end_date=${endDate}&limit=${limit}`;
        } else {
          endpoin = `${url.rujukan.listData}?page=${activePaginate}&limit=${limit}`;
        }
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              let key = 'jenispemeriksaan';
              const result = data[key].data;
              dispatch(getRujukanList(result));
              dispatch(
                setRujukanPagination({
                  current_page: data[key].current_page,
                  per_page: data[key].per_page,
                  total: data[key].total,
                  last_page: data[key].last_page,
                  from: data[key].from,
                }),
              );
            } else {
              if (data.message.end_date) dispatch(fetchError(data.message.end_date[0]));
              if (data.message.start_date) dispatch(fetchError(data.message.start_date[0]));
              if (data.message.keyword) dispatch(fetchError(data.message.keyword[0]));
            }
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getPemerikasaanList: (kode, id_rujukan = null) => {
    return (dispatch, getState) => {
      const { activePaginate, keyword, limit } = getState().rujukan;
      dispatch(fetchStart());
      let endpoin;
      if (keyword) {
        dispatch(setActiveRujukanPagination(1));
        if (id_rujukan) {
          endpoin = `${url.rujukan.jenisPemeriksaan}?keyword=${keyword}&kode_cabang=${kode}&id_rujukan=${id_rujukan}`;
        } else {
          endpoin = `${url.rujukan.jenisPemeriksaan}?keyword=${keyword}&kode_cabang=${kode}`;
        }
      } else {
        if (id_rujukan) {
          endpoin = `${url.rujukan.jenisPemeriksaan}?page=${activePaginate}&kode_cabang=${kode}&limit=${limit}&id_rujukan=${id_rujukan}`;
        } else {
          endpoin = `${url.rujukan.jenisPemeriksaan}?page=${activePaginate}&kode_cabang=${kode}&limit=${limit}`;
        }
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              let key = 'jenispemeriksaan';
              const result = data[key].data.data;
              const inject = result.map(item => {
                return {
                  ...item,
                  id_rujukan: data[key].id_rujukan,
                };
              });
              dispatch(getRujukanList(inject));
              dispatch(
                setRujukanPagination({
                  current_page: data[key].data.current_page,
                  per_page: data[key].data.per_page,
                  total: data[key].data.total,
                  last_page: data[key].data.last_page,
                  from: data[key].data.from,
                }),
              );
            } else {
              if (data.message.end_date) dispatch(fetchError(data.message.kode[0]));
              if (data.message.keyword) dispatch(fetchError(data.message.keyword[0]));
            }
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getPemerikasaanListCreate: (kode, id_rujukan = null) => {
    return (dispatch, getState) => {
      const { activePaginate, keyword, limit } = getState().rujukan;
      dispatch(fetchStart());
      let endpoin;
      if (keyword) {
        dispatch(setActiveRujukanPagination(1));
        if (id_rujukan) {
          endpoin = `${url.rujukan.jenisPemeriksaan}?keyword=${keyword}&kode_cabang=${kode}&id_rujukan=${id_rujukan}`;
        } else {
          endpoin = `${url.rujukan.jenisPemeriksaan}?keyword=${keyword}&kode_cabang=${kode}`;
        }
      } else {
        if (id_rujukan) {
          endpoin = `${url.rujukan.jenisPemeriksaan}?page=${activePaginate}&kode_cabang=${kode}&limit=${limit}&id_rujukan=${id_rujukan}`;
        } else {
          endpoin = `${url.rujukan.jenisPemeriksaan}?page=${activePaginate}&kode_cabang=${kode}&limit=${limit}`;
        }
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              let key = 'jenispemeriksaan';
              const result = data[key].data.data;
              const inject = result.map(item => {
                return {
                  ...item,
                  id_rujukan: data[key].id_rujukan,
                  show_harga: data[key].show_harga,
                };
              });
              dispatch(getRujukanList(inject));
              dispatch(
                setRujukanPagination({
                  current_page: data[key].data.current_page,
                  per_page: data[key].data.per_page,
                  total: data[key].data.total,
                  last_page: data[key].data.last_page,
                  from: data[key].data.from,
                }),
              );
            } else {
              if (data.message.end_date) dispatch(fetchError(data.message.kode[0]));
              if (data.message.keyword) dispatch(fetchError(data.message.keyword[0]));
            }
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getKlinikList: async (id, page) => {
    if (id === '') {
      return await fetch(`${url.baseUrl}${url.rujukan.klinikList}?page=${page}`, {
        headers: new Headers({
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        }),
      });
    } else {
      return await fetch(`${url.baseUrl}${url.rujukan.klinikList}?keyword=${id}&page=${page}`, {
        headers: new Headers({
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        }),
      });
    }
  },

  getKlinikTitle: () => {
    return (dispatch, getState) => {
      dispatch(fetchStart());

      return axios
        .get(url.rujukan.klinikTitle)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              const FormatData = data.kliniktitle.map(item => {
                return {
                  label: item.nama,
                  value: item.kode,
                  gender: item.kelamin,
                };
              });

              dispatch(setRujukanListTitle(FormatData));
            }
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  saveData: data => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(url.rujukan.insert, data)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              return data;
            } else {
              if (data.message.alamat) dispatch(fetchError(data.message.alamat));
              if (data.message.email) dispatch(fetchError(data.message.email));
              if (data.message.jenis_kelamin) dispatch(fetchError(data.message.jenis_kelamin));
              if (data.message.kota) dispatch(fetchError(data.message.kota));
              if (data.message.nama) dispatch(fetchError(data.message.nama));
              if (data.message.nama_cabang) dispatch(fetchError(data.message.nama_cabang));
              if (data.message.nik) dispatch(fetchError(data.message.nik));
              if (data.message.no_hp) dispatch(fetchError(data.message.no_hp));
              if (data.message.tgl_lahir) dispatch(fetchError(data.message.tgl_lahir));
              if (data.message.title) dispatch(fetchError(data.message.title));
              if (data.message.kode_cabang) dispatch(fetchError(data.message.kode_cabang));
              return data;
            }
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { success: false, message: error.message };
        });
    };
  },

  editData: data => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(url.rujukan.update, data)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              return data;
            } else {
              if (data.message.alamat) dispatch(fetchError(data.message.alamat));
              if (data.message.email) dispatch(fetchError(data.message.email));
              if (data.message.jenis_kelamin) dispatch(fetchError(data.message.jenis_kelamin));
              if (data.message.kota) dispatch(fetchError(data.message.kota));
              if (data.message.nama) dispatch(fetchError(data.message.nama));
              if (data.message.nama_cabang) dispatch(fetchError(data.message.nama_cabang));
              if (data.message.nik) dispatch(fetchError(data.message.nik));
              if (data.message.no_hp) dispatch(fetchError(data.message.no_hp));
              if (data.message.tgl_lahir) dispatch(fetchError(data.message.tgl_lahir));
              if (data.message.title) dispatch(fetchError(data.message.title));
              if (data.message.kode_cabang) dispatch(fetchError(data.message.kode_cabang));
              return data;
            }
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { success: false, message: error.message };
        });
    };
  },

  getReport: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(url.report.jenisPemeriksaan, id)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              return data.linkfile;
            }
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { success: false, message: error.message };
        });
    };
  },

  deleteReport: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(url.delete.jenisPemeriksaanDelete, id)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              return data;
            }
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { success: false, message: error.message };
        });
    };
  },

  getDetail: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .get(`${url.rujukan.edit}/${id}`)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return {
              status: true,
              message: data.message,
              data: data.getjenispemeriksaanedit,
            };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message, data: [] };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message, data: [] };
        });
    };
  },
};

export default rujukanLoad;
