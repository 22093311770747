import {
  SET_LIST_HOME_ACTIVE_PAGINATION,
  SET_LIST_HOME_KEYWORD,
  SET_LIST_HOME_DATA,
  SET_LIST_HOME_PAGINATION,
  SET_HOME_SELECTED,
  SET_HOME_SELECTED_FILE,
  SET_HOME_DEFAULT_STATE,
} from '../../@jumbo/constants/ActionTypes';

export const getHomeList = data => {
  return dispatch => {
    dispatch({
      type: SET_LIST_HOME_DATA,
      payload: data,
    });
  };
};

export const setHomePagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_LIST_HOME_PAGINATION,
      payload: pagination,
    });
  };
};

export const setActiveHomePagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_LIST_HOME_ACTIVE_PAGINATION,
      payload: pagination,
    });
  };
};

export const setKeywordHome = keyword => {
  return dispatch => {
    dispatch({
      type: SET_LIST_HOME_KEYWORD,
      payload: keyword,
    });
  };
};

export const setSelectedData = data => {
  return dispatch => {
    dispatch({
      type: SET_HOME_SELECTED,
      payload: data,
    });
  };
};

export const setSelectedFile = data => {
  return dispatch => {
    dispatch({
      type: SET_HOME_SELECTED_FILE,
      payload: data,
    });
  };
};

export const setHomeDefault = () => {
  return dispatch => {
    dispatch({
      type: SET_HOME_DEFAULT_STATE,
      payload: {},
    });
  };
};
