import React, { Fragment, useEffect } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { FaClinicMedical } from 'react-icons/fa';
import { BsFillPersonBadgeFill, BsAsterisk } from 'react-icons/bs';
import { GoChecklist } from 'react-icons/go';
import { BiSearch } from 'react-icons/bi';
import TableAddPasien from './TableAddPasien';
import LoadData from '../../../services/rujukan';
import { useDispatch, useSelector } from 'react-redux';
import { setRujukanKeyword, setRujukanDefaultState } from '../../../redux/actions';
import { AsyncPaginate } from 'react-select-async-paginate';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import TableDetailPasien from './TableDetailPasien';

const Panggilan = [
  { value: 'Ibu', label: 'Ibu' },
  { value: 'Bapak', label: 'Bapak' },
  { value: 'Nyonya', label: 'Nyonya' },
];
const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'data pasien rujukan', link: '/data-pasien-rujukan' },
  { label: 'tambah data pasien rujukan', isActive: true },
];
const DetailPasienRujukan = () => {
  const dispatch = useDispatch();
  const [keywordLocal, setKeyword] = React.useState('');
  const [selectedPrice, setSelectedPrice] = React.useState([]);
  const [value, onChange] = React.useState(null);
  const [valueList, setValueList] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [nikdata, setNik] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [alamat, setAlamat] = React.useState('');
  const [nama, setNama] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [kota, setKota] = React.useState('');
  const [telp, setTelp] = React.useState('');
  const [dateSpace, setDateSpace] = React.useState('');
  const [jk, setJk] = React.useState('');
  const [klinik, setKlinik] = React.useState('');
  const { titledata } = useSelector(state => state.rujukan);
  const [selectedData, setSelectedData] = React.useState([]);
  const [intialSelected, setIntialSelected] = React.useState([]);
  const history = useHistory();
  const { id } = useParams();
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    dispatch(setRujukanDefaultState());
    dispatch(LoadData.getKlinikTitle());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const rest = await dispatch(LoadData.getDetail(id));
      if (rest.status === true) {
        const data = rest.data.data;
        console.log(moment(data.tgl_sampling).format('Y-MM-DD[T]HH:mm'));
        setNik(data.nik);
        setValueList({ label: `${data.nama_cabang}`, value: data.kode_cabang });

        setNama(data.nama);
        //find titleData by id
        const ChoosetitleData = titledata.find(item => item.value === data.title);
        setTitle(ChoosetitleData);
        setJk(data.jenis_kelamin);
        setDob(data.tgl_lahir);
        setEmail(data.email);
        setAlamat(data.alamat);
        setKota(data.kota);
        setTelp(data.no_hp);
        setDateSpace(moment(data.tgl_sampling).format('Y-MM-DD[T]HH:mm'));
        // console.log(rest.data.detail);
        rest.data.detail.forEach(item => {
          item.nama_test = item.nama_pemeriksa;
          delete item.nama_pemeriksa;
          delete item['id_rujukan_test'];
        });
        setIntialSelected(rest.data.detail);
      }
    };

    fetchData();
  }, [dispatch, id, titledata]);

  const handleKeyword = () => {
    dispatch(setRujukanKeyword(keywordLocal));
  };

  const handleChange = value => {
    console.log(value);
    setValueList(value);
    setKlinik(value.data);
  };

  const handleChangeSelect = value => {
    setTitle(value);
    setJk(value.gender);
  };

  const handleSave = async value => {
    const obj = {
      id_rujukan: id,
      kode_cabang: valueList.value,
      nama_cabang: valueList.label,
      tgl_lahir: moment(dob).format('Y-MM-DD'),
      tgl_sampling: moment(dateSpace).format('Y-MM-DD HH:mm'),
      jenis_kelamin: jk,
      alamat: alamat,
      kota: kota,
      nik: nikdata,
      email: email,
      no_hp: telp,
      title: title.value,
      nama: nama,
      data: selectedData,
    };
    const result = await dispatch(LoadData.saveData(obj));
    if (result.success) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        history.push('/data-pasien-rujukan');
      });
    }
  };

  const HandleEdit = async value => {
    const obj = {
      id_rujukan: id,
      kode_cabang: valueList.value,
      nama_cabang: valueList.label,
      tgl_lahir: moment(dob).format('Y-MM-DD'),
      tgl_sampling: moment(dateSpace).format('Y-MM-DD HH:mm'),
      jenis_kelamin: jk,
      alamat: alamat,
      kota: kota,
      nik: nikdata,
      email: email,
      no_hp: telp,
      title: title.value,
      nama: nama,
      data: selectedData,
    };
    const result = await dispatch(LoadData.editData(obj));
    if (result.success) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        history.push('/data-pasien-rujukan');
      });
    }
  };

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    let filteredOptions;
    let responseJSON;

    const response = await LoadData.getKlinikList(searchQuery, page);
    responseJSON = await response.json();
    filteredOptions = responseJSON.getKlinik.data.map(item => ({
      data: item,
      value: item.kode,
      label: `${item.kode}-${item.nama}`,
    }));
    const { last_page, current_page } = responseJSON.getKlinik;
    return {
      options: filteredOptions,
      hasMore: current_page === last_page ? false : true,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };
  return (
    <Fragment>
      <PageContainer heading={'Tambah Data Pasien Rujukan'} breadcrumbs={breadcrumbs} />
      <div className="wrap-pasien-rujukan">
        <h3>Detail Data</h3>
        <div className="line " />
        <div className="subtitle-card-add">
          <p>Informasi Klinik</p>
          <FaClinicMedical />
        </div>
        <div className="wrap-input-form-asyn">
          <div className="wrap-input-add-asyn">
            <p>Klinik Rujukan</p>
            {/* <input type="text" placeholder="Klinik Rujukan" /> */}
            <AsyncPaginate
              value={valueList}
              loadOptions={loadOptions}
              onChange={handleChange}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
              placeholder="cari id klinik"
              additional={{
                page: 1,
              }}
              isDisabled={true}
            />
          </div>
        </div>
        <div className="line " />
        <div className="subtitle-card-add">
          <p>Informasi Pasien</p>
          <BsFillPersonBadgeFill />
        </div>

        <div className="wrap-input-form">
          <div className="wrap-input-add-panggilan">
            <p>
              Title
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            {/* <input type="text" placeholder="Title" /> */}
            <Select options={titledata} onChange={handleChangeSelect} value={title} isDisabled={true} />
          </div>
          <div className="wrap-input-add">
            <p>
              Nama
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            <input type="text" placeholder="Nama " value={nama} onChange={e => setNama(e.target.value)} disabled />
          </div>
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add">
            <p>Nik</p>
            <input type="text" placeholder="Nik" value={nikdata} onChange={e => setNik(e.target.value)} disabled />
          </div>
          <div className="wrap-input-add">
            <p>Email</p>
            <input type="text" placeholder="Email " value={email} onChange={e => setEmail(e.target.value)} disabled />
          </div>
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add">
            <p>
              Tgl Lahir
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            <input type="Date" placeholder="DOB" value={dob} onChange={e => setDob(e.target.value)} disabled />
          </div>
          <div className="wrap-input-add">
            <p>
              Jenis Kelamin
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            <input type="text" placeholder="Jenis Kelamin" disabled value={jk} />
          </div>
        </div>
        <div className="wrap-input-add-textarea">
          <p>Alamat</p>
          <textarea placeholder="alamat" value={alamat} onChange={e => setAlamat(e.target.value)} disabled />
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add">
            <p>Kota</p>
            <input type="text" placeholder="Kota" value={kota} onChange={e => setKota(e.target.value)} disabled />
          </div>
          <div className="wrap-input-add">
            <p>No Telepon</p>
            <input type="number" placeholder="No Telepon" value={telp} onChange={e => setTelp(e.target.value)} disabled />
          </div>
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add-spesiment">
            <p>
              Tgl Pengambilan Darag / spesiment
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            {/* <input type="Date" placeholder="date" value={dateSpace} onChange={e => setDateSpace(e.target.value)} /> */}
            <TextField
              className="date-custome"
              id="datetime-local"
              // label="Next appointment"
              type="datetime-local"
              // className={classes.textField}
              value={dateSpace}
              onChange={e => {
                setDateSpace(e.target.value);
              }}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </div>
          <div className="wrap-input-add">
            <p></p>
            <span style={{ color: 'red', fontSize: 16, fontWeight: 500 }}>
              <BsAsterisk color="red" size={'10px'} />) tanda ini wajib untuk di isi
            </span>
          </div>
        </div>
        <div className="line " />

        <div className="subtitle-card-add">
          <p>Jenis Pemeriksaan</p>
          <GoChecklist />
        </div>
        <div className="container-table-add">
          <TableDetailPasien
            id={valueList.value}
            setSelectedPrice={e => setSelectedPrice(e)}
            selectedPrice={selectedPrice}
            selectedData={selectedData}
            setSelectedData={e => setSelectedData(e)}
            intialSelected={intialSelected}
            id_rujukan={id}
            showOnly={true}
          />
          {/* <div className="btn-submit">
            <div className="total-add">
              <p>Total</p>
              <p>{selectedPrice.reduce((partialSum, a) => partialSum + parseInt(a), 0)}</p>
            </div>
            <button onClick={HandleEdit}>Edit</button>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default DetailPasienRujukan;
