import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import {
  getPASIENList,
  setActivePASIENPagination,
  setPASIENPagination,
  setKeywordHome,
  setSelectedFile,
} from '../../redux/actions';
import _ from 'lodash';

const PasienLoad = {
  getHasilBaru: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword, startDate, endDate } = getState().pasien;
      const {
        authUser: { role },
      } = getState().auth;
      dispatch(fetchStart());

      let endpoin;
      if (keyword) {
        dispatch(setActivePASIENPagination(1));
        if (role === 'personal') {
          endpoin = `${url.personal.hasil}?keyword=${keyword}`;
        } else if (role === 'instansi') {
          endpoin = `${url.instansi.hasil}?keyword=${keyword}`;
        } else {
          endpoin = `${url.dokter.hasil}?keyword=${keyword}`;
        }
      } else {
        if (startDate !== '' && endDate !== '') {
          if (role === 'personal') {
            endpoin = `${url.personal.hasil}?page=${activePaginate}&start_date=${startDate}&end_date=${endDate}`;
          } else if (role === 'instansi') {
            endpoin = `${url.instansi.hasil}?page=${activePaginate}&start_date=${startDate}&end_date=${endDate}`;
          } else {
            endpoin = `${url.dokter.hasil}?page=${activePaginate}&start_date=${startDate}&end_date=${endDate}`;
          }
        } else {
          if (role === 'personal') {
            endpoin = `${url.personal.hasil}?page=${activePaginate}`;
          } else if (role === 'instansi') {
            endpoin = `${url.instansi.hasil}?page=${activePaginate}`;
          } else {
            endpoin = `${url.dokter.hasil}?page=${activePaginate}`;
          }
        }
      }

      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              let key;
              if (role === 'personal') {
                key = 'personalHasil';
              } else if (role === 'instansi') {
                key = 'instansiHasil';
              } else {
                key = 'doktorHasil';
              }
              const result = data[key].data;
              dispatch(getPASIENList(result));
              dispatch(
                setPASIENPagination({
                  current_page: data[key].current_page,
                  per_page: data[key].per_page,
                  total: data[key].total,
                  last_page: data[key].last_page,
                  from: data[key].from,
                }),
              );
            } else {
              if (data.message.end_date) dispatch(fetchError(data.message.end_date[0]));
              if (data.message.start_date) dispatch(fetchError(data.message.start_date[0]));
              if (data.message.keyword) dispatch(fetchError(data.message.keyword[0]));
            }
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  updateStatusFile: (file, nolab) => {
    return (dispatch, getState) => {
      const {
        authUser: { role },
      } = getState().auth;
      dispatch(fetchStart());

      let endpoin;
      if (role === 'instansi') {
        endpoin = `${url.instansi.updateStatus}/${nolab}`;
        axios
          .get(endpoin)
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.success) {
                dispatch(fetchSuccess());
                dispatch(PasienLoad.getHasilBaru());
                window.open(`${file}`, '_blank');
              } else {
                dispatch(fetchError(data.message));
              }
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error.message));
          });
      }
      if (role === 'doktor') {
        endpoin = `${url.dokter.updateStatus}/${nolab}`;
        axios
          .get(endpoin)
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.success) {
                dispatch(fetchSuccess());
                dispatch(PasienLoad.getHasilBaru());
                window.open(`${file}`, '_blank');
              } else {
                dispatch(fetchError(data.message));
              }
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error.message));
          });
      }

      if (role === 'personal') {
        endpoin = `${url.personal.updateStatus}/${nolab}`;
        axios
          .get(endpoin)
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.success) {
                dispatch(fetchSuccess());
                dispatch(PasienLoad.getHasilBaru());
                window.open(`${file}`, '_blank');
              } else {
                dispatch(fetchError(data.message));
              }
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error.message));
          });
      }
    };
  },
};

export default PasienLoad;
