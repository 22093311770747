import {
  SET_LIST_RUJUKAN_ACTIVE_PAGINATION,
  SET_LIST_RUJUKAN_KEYWORD,
  SET_LIST_RUJUKAN_DATA,
  SET_LIST_RUJUKAN_PAGINATION,
  SET_RUJUKAN_SELECTED,
  SET_RUJUKAN_SELECTED_FILE,
  SET_RUJUKAN_DEFAULT_STATE,
  SET_RUJUKAN_STARTDATE,
  SET_RUJUKAN_ENDDATE,
  SET_LIST_RUJUKAN_LIMIT,
  SET_LIST_RUJUKAN_TITLE,
} from '../../@jumbo/constants/ActionTypes';

export const getRujukanList = data => {
  return dispatch => {
    dispatch({
      type: SET_LIST_RUJUKAN_DATA,
      payload: data,
    });
  };
};

export const setRujukanKeyword = keyword => {
  return dispatch => {
    dispatch({
      type: SET_LIST_RUJUKAN_KEYWORD,
      payload: keyword,
    });
  };
};

export const setRujukanPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_LIST_RUJUKAN_PAGINATION,
      payload: pagination,
    });
  };
};

export const setActiveRujukanPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_LIST_RUJUKAN_ACTIVE_PAGINATION,
      payload: pagination,
    });
  };
};

export const setRujukanLimit = limit => {
  return dispatch => {
    dispatch({
      type: SET_LIST_RUJUKAN_LIMIT,
      payload: limit,
    });
  };
};

export const setRujukanStartDate = date => {
  return dispatch => {
    dispatch({
      type: SET_RUJUKAN_STARTDATE,
      payload: date,
    });
  };
};

export const setRujukanEndDate = date => {
  return dispatch => {
    dispatch({
      type: SET_RUJUKAN_ENDDATE,
      payload: date,
    });
  };
};

export const setRujukanDefaultState = () => {
  return dispatch => {
    dispatch({
      type: SET_RUJUKAN_DEFAULT_STATE,
    });
  };
};

export const setRujukanListTitle = payload => {
  return dispatch => {
    dispatch({
      type: SET_LIST_RUJUKAN_TITLE,
      payload: payload,
    });
  };
};
