import React, { Fragment } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import './DataHasilPasien.css';
import { makeStyles, lighten } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { BiSearch, BiEdit } from 'react-icons/bi';
import { AiFillPrinter } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { BsEyeFill } from 'react-icons/bs';
import { MdOutlineAdd } from 'react-icons/md';
import LoadData from '../../../services/rujukan';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import url from '../../../@jumbo/constants/UrlConstant';
import {
  setActiveRujukanPagination,
  setRujukanLimit,
  setRujukanStartDate,
  setRujukanEndDate,
  setRujukanKeyword,
  setRujukanDefaultState,
} from '../../../redux/actions';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Biogen 1', 'nama', 'Laki-Laki', '04-01-2022', 'Sudah Proses'),
  createData('Biogen 2', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 3', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 4', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 5', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 6', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 7', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 8', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 9', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
  createData('Biogen 10', 'nama', 'Laki-Laki', '04-01-2022', 'Di Proses'),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'klinikrujukan',
    numeric: false,
    disablePadding: true,
    label: 'Klinik Rujukan',
  },
  { id: 'nama', numeric: true, disablePadding: false, label: 'Nama' },
  {
    id: 'jeniskelamin',
    numeric: true,
    disablePadding: false,
    label: 'Jenis Kelamin',
  },
  {
    id: 'tglregistrasi',
    numeric: true,
    disablePadding: false,
    label: 'Tgl Registrasi',
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'aksi', numeric: true, disablePadding: false, label: 'Aksi' },
];

function HeaderTable(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="table-head-hasil-rujukan">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            className="checkTab"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

HeaderTable.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = props => {
  const { numSelected, handleReport, HandleDelete } = props;
  return (
    <div className={`head-toolbar-action ${numSelected ? 'active' : ''}`}>
      {numSelected > 0 ? (
        <div className="header-table-action">
          <p>{numSelected} selected</p>
          <button className="active" onClick={handleReport}>
            <p>Cetak</p> <AiFillPrinter />
          </button>
          <button className="active" onClick={HandleDelete}>
            <p>Delete</p> <MdDelete />
          </button>
        </div>
      ) : (
        <div className="header-table-action">
          <button disabled>
            <p>Cetak</p> <AiFillPrinter />
          </button>
          <button disabled>
            <p>Delete</p> <MdDelete />
          </button>
        </div>
      )}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleReport: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Data Pasien Rujukan', isActive: true },
];
const DataHasilPasien = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const { rujukanData, pagination, activePaginate, limit, keyword, startDate, endDate } = useSelector(
    state => state.rujukan,
  );
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [startDateLocal, setStartDate] = React.useState('');
  const [endDateLocal, setEndDate] = React.useState('');
  const [keywordLocal, setKeyword] = React.useState('');

  const HandlePagination = (event, current_page) => {
    dispatch(setActiveRujukanPagination(current_page));
  };

  const handleDate = () => {
    if (startDateLocal) {
      dispatch(setRujukanStartDate(moment(startDateLocal).format('Y-MM-DD')));
    } else {
      dispatch(setRujukanStartDate(''));
    }

    if (endDateLocal) {
      dispatch(setRujukanEndDate(moment(endDateLocal).format('Y-MM-DD')));
    } else {
      dispatch(setRujukanEndDate(''));
    }
  };

  React.useEffect(() => {
    dispatch(setRujukanDefaultState());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(LoadData.getRujukanList());
  }, [dispatch, activePaginate, limit, keyword, startDate, endDate]);

  const resetSearch = () => {
    document.getElementById('start').value = '';
    document.getElementById('end').value = '';
    window.location.reload();
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rujukanData.map(n => n.id_rujukan);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleKeyword = () => {
    dispatch(setRujukanKeyword(keywordLocal));
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const HandleRowPagination = (event, current_page) => {
    dispatch(setRujukanLimit(event.target.value));
  };

  const HandleReport = async () => {
    const obj = {
      id_rujukan: selected,
    };
    const link = await dispatch(LoadData.getReport(obj));
    window.open(`${link}`, '_blank');
  };
  const HandleDelete = async () => {
    const obj = {
      id_rujukan: selected,
    };
    const result = await dispatch(LoadData.deleteReport(obj));
    if (result.success) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        setSelected([]);
        dispatch(setRujukanDefaultState());
        dispatch(LoadData.getRujukanList());
      });
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <PageContainer heading="Data Pasien Rujukan" breadcrumbs={breadcrumbs} />
      <div className="table-wrap-hasil-terbaru">
        <div className="head-search">
          <div className="wrap-date">
            <p>Cari Data :</p>
            <input type="date" name="start" id="start" onChange={e => setStartDate(e.target.value)} />
            <p>-</p>
            <input type="date" name="end" id="end" onChange={e => setEndDate(e.target.value)} />
            <button onClick={handleDate}>Cari</button>
            <button onClick={resetSearch}>Reset</button>
          </div>
          <div className="btn-daftar-rujukan">
            <div className="search-style-rujuk">
              <input type="text" placeholder="Search By" onChange={e => setKeyword(e.target.value)} />
              <button className="search-rujukan" onClick={handleKeyword}>
                <BiSearch />
              </button>
            </div>
            <div className="add-btn-rujuk">
              <button onClick={() => history.push('/add-pasien-rujukan')}>
                <MdOutlineAdd size={20} />
                Daftar
              </button>
            </div>
          </div>
        </div>

        <EnhancedTableToolbar numSelected={selected.length} handleReport={HandleReport} HandleDelete={HandleDelete} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <HeaderTable
              classes={classes}
              numSelected={selected.length}
              //   order={order}
              //   orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              //   onRequestSort={handleRequestSort}
              rowCount={rujukanData.length}
            />

            <TableBody>
              {stableSort(rujukanData, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id_rujukan);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow className={'checkTab'} hover role="checkbox" tabIndex={-1} key={row.id_rujukan}>
                      <TableCell padding="checkbox" onClick={event => handleClick(event, row.id_rujukan)}>
                        <Checkbox
                          className="checkTab"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                        {row.klinik_rujukan}
                      </TableCell>
                      <TableCell align="left">{row.nama}</TableCell>
                      <TableCell align="left">{row.jenis_kelamin}</TableCell>
                      <TableCell align="left">{row.tanggal_registrasi}</TableCell>
                      <TableCell align="left">
                        {row.flagx === 'Belum diproses' ? (
                          <span className="text-action">{row.flagx}</span>
                        ) : (
                          <span className="text-action-danger">{row.flagx}</span>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <div className="action-tab-hasil-rujukan">
                          <button onClick={() => history.push(`/detail-pasien-rujukan/${row.id_rujukan}`)}>
                            <BsEyeFill />
                          </button>
                          {row.flagx === 'Belum diproses' ? (
                            <button onClick={() => history.push(`/edit-pasien-rujukan/${row.id_rujukan}`)}>
                              <BiEdit />
                            </button>
                          ) : null}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={pagination.total}
          rowsPerPage={limit}
          page={activePaginate - 1}
          onPageChange={(e, c) => HandlePagination(e, c + 1)}
          onRowsPerPageChange={(e, c) => HandleRowPagination(e, c)}
        />
      </div>
    </Fragment>
  );
};

export default DataHasilPasien;
