import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Home from './Home';
import Pasien from './Pasien';
import Rujukan from './Rujukan';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    home: Home,
    pasien: Pasien,
    rujukan: Rujukan,
  });
