import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';

function saveToLocalStorage(state) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('state', serializedState);
}
function loadFromLocalStorage() {
  const serializedState = localStorage.getItem('state');
  if (serializedState === null) return undefined;
  const parsedSerializedState = JSON.parse(serializedState);
  return parsedSerializedState;
}

const history = createBrowserHistory();
const presistedState = loadFromLocalStorage();
const routeMiddleware = routerMiddleware(history);
const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
  const store = createStore(reducers(history), presistedState, bindMiddleware([routeMiddleware, thunk]));
  store.subscribe(() => {
    saveToLocalStorage({
      home: store.getState().home,
      auth: store.getState().auth,
    });
  });
  return store;
}
export default configureStore;
export { history };
