import React, { Fragment } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import './Changepass.css';
import { FaUserLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import updateLoad from '../../../services/update';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Change password', isActive: true },
];
const ChangePassword = () => {
  const {
    authUser: { id },
    authUser,
  } = useSelector(({ auth }) => auth);
  const [email, setEmail] = React.useState(authUser.email);
  const [password, setPassword] = React.useState('');
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const HanldeSubmit = async () => {
    const obj = {
      id,
      email,
      password,
    };
    const result = await dispatch(updateLoad.updatePassword(obj));
    if (result.status) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        return true;
      });
    }
  };

  return (
    <Fragment>
      <PageContainer heading="Change Password" breadcrumbs={breadcrumbs} />
      <div className="change-pass">
        <h3>Change Password</h3>
        <div className="line " />
        <div className="subtitle-card">
          <p>Informasi Account</p>
          <FaUserLock />
        </div>
        <div className="wrap-input-all">
          <p>Email</p>
          <input type="text" placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
        </div>
        <div className="wrap-input-all">
          <p>Password</p>
          <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
        </div>
        <div className="line " />
        <div className="btn-submit">
          <button onClick={HanldeSubmit}>save</button>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
