import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import TabInformasi from './TabInformasi';
import TabHasilTerbaru from './TabHasilTerbaru';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveHomePagination } from '../../../redux/actions';

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(8),
    padding: '6px 0px',
    '&:hover': {
      color: '#026EB8',
      opacity: 1,
    },
    '&$selected': {
      color: '#026EB8',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#026EB8',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: '#f4f4f7',
    color: '#C4C4C4',
    textAlign: 'left',
  },
  indicator: {
    backgroundColor: '#026EB8',
    width: '5% !important',
  },
})(Tabs);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Home', isActive: true },
];

const Home = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const {
    authUser: { role },
  } = useSelector(({ auth }) => auth);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setActiveHomePagination(1));
  };

  return (
    <Fragment>
      <PageContainer heading="Home" breadcrumbs={breadcrumbs} />
      <Box className={classes.root}>
        <Box className={classes.demo1}>
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Informasi" />
            {role === 'instansi' && <AntTab label="Hasil Terbaru" />}
          </AntTabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TabInformasi />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabHasilTerbaru />
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default Home;
