import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { Fragment } from 'react';
import './Home.css';
import { VscFilePdf } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import homeLoad from '../../../services/home';
import url from '../../../@jumbo/constants/UrlConstant';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Home', link: '/home' },
  { label: 'Detail-informasi', isActive: true },
];

const dataFile = [
  {
    nama: 'tes',
  },
  {
    nama: 'tes',
  },
  {
    nama: 'tes',
  },
  {
    nama: 'tes',
  },
  {
    nama: 'tes',
  },
  {
    nama: 'tes',
  },
];

const DetailInformasi = () => {
  const { selectedData, selectedFile } = useSelector(({ home }) => home);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(homeLoad.getFile(selectedData.id_pengumuman));
    console.log('TESSSS', selectedFile.length);
  }, [dispatch, selectedData]);

  return (
    <Fragment>
      <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs} />
      <div className="detail-informasi">
        <h2>{selectedData.title}</h2>
        {/* <p>30 mei 2022 02:19:54</p> */}

        <div className="deskripsi-detail-informasi">
          <p>Deskripsi</p>
          <div dangerouslySetInnerHTML={{ __html: selectedData.deskripsi }} />
        </div>

        <div className="attachment-detail-informasi-wrap">
          <p>Attachment</p>
          <div className="attachment-detail-informasi">
            {selectedFile.length <= 0 ? (
              <div className="not-data">
                <img src="/images/no data.png" alt="" />
                <p>Tidak ada file Attachment</p>
              </div>
            ) : (
              selectedFile.map((d, i) => {
                const nama_file = d.split('/')[2];
                return (
                  <div className="item-atta-detail-informasi">
                    <div className="content-atta">
                      <div className="icon-file-detail">
                        <VscFilePdf size={'30px'} />
                      </div>
                      <div className="des-file-detail">
                        <p>{nama_file}</p>
                        {/* <p className="file-size-style">size file</p> */}
                        <a href={`${d}`} target="_blank">
                          <button>views</button>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

            {/* <h1>tes</h1> */}
            {/* /images/labiogen.png */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailInformasi;
