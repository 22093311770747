const url = {
  baseUrl: 'https://api.klinikrapha.co.id/api',
  baseContent: 'https://api.klinikrapha.co.id',
  personalAuth: {
    login: '/personal-auth/login',
    logout: '/personal-auth/logout',
    refresh: '/personal-auth/refresh',
    me: '/personal-auth/me',
  },
  doktorAuth: {
    login: '/doktor-auth/login',
    logout: '/doktor-auth/logout',
    refresh: '/doktor-auth/refresh',
    me: '/doktor-auth/me',
  },
  instansiAuth: {
    login: '/instansi-auth/login',
    logout: '/instansi-auth/logout',
    refresh: '/instansi-auth/refresh',
    me: '/instansi-auth/me',
  },
  personal: {
    list: '/personal/list',
    getFile: '/personal/getfile',
    hasil: '/personal/hasil',
    update: '/personal/update',
    updateStatus: '/personal/hasil/updatestatusfile',
  },
  instansi: {
    list: '/instansi/list',
    getFile: '/instansi/getfile',
    hasil: '/instansi/hasil',
    update: '/instansi/update',
    updateStatus: '/instansi/hasil/updatestatusfile',
  },
  dokter: {
    list: '/doktor/list',
    getFile: '/doktor/getfile',
    hasil: '/doktor/hasil',
    update: '/doktor/update',
    updateStatus: '/doktor/hasil/updatestatusfile',
  },
  rujukan: {
    klinikList: '/rujukan-daftar/klinik-list',
    klinikTitle: '/rujukan-daftar/klinik-title',
    jenisPemeriksaan: '/rujukan-daftar/jenis-pemeriksaan/list',
    tambahPemeriksaan: '/rujukan-daftar/jenis-pemeriksaan/insert',
    listData: '/rujukan-daftar/jenis-pemeriksaan/list-data',
    listDetail: '/rujukan-daftar/jenis-pemeriksaan/list-data-detail',
    edit: '/rujukan-daftar/jenis-pemeriksaan/edit',
    update: '/rujukan-daftar/jenis-pemeriksaan/update',
    insert: '/rujukan-daftar/jenis-pemeriksaan/insert',
  },
  report: {
    jenisPemeriksaan: '/report/jenis-pemeriksaan',
  },
  delete: {
    jenisPemeriksaanDelete: '/delete/jenis-pemeriksaan-delete',
  },
};

export default url;
