import React from 'react';
import { PostAdd } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';
import { ImHome3 } from 'react-icons/im';
import { BsPersonLinesFill } from 'react-icons/bs';
import { ImLab } from 'react-icons/im';
import { FaUserLock } from 'react-icons/fa';

export const sidebarNavs = [
  {
    name: 'Dashboard',
    type: 'section',
    children: [
      {
        name: 'Home',
        type: 'item',
        icon: <ImHome3 />,
        link: '/home',
      },
      {
        name: 'Hasil Pasien',
        type: 'item',
        icon: <BsPersonLinesFill />,
        link: '/hasil-pasien',
      },
      // {
      //   name: 'Data Pasien Rujukan',
      //   type: 'item',
      //   icon: <ImLab />,
      //   link: '/data-pasien-rujukan',
      // },
      {
        name: 'Change Password',
        type: 'item',
        icon: <FaUserLock />,
        link: '/change-password',
      },
    ],
  },
];

export const sidebarNavsInstansi = [
  {
    name: 'Dashboard',
    type: 'section',
    children: [
      {
        name: 'Home',
        type: 'item',
        icon: <ImHome3 />,
        link: '/home',
      },
      {
        name: 'Hasil Pasien',
        type: 'item',
        icon: <BsPersonLinesFill />,
        link: '/hasil-pasien',
      },
      // {
      //   name: 'Data Pasien Rujukan',
      //   type: 'item',
      //   icon: <ImLab />,
      //   link: '/data-pasien-rujukan',
      // },
      {
        name: 'Change Password',
        type: 'item',
        icon: <FaUserLock />,
        link: '/change-password',
      },
    ],
  },
];

export const sidebarNavsPersonal = [
  {
    name: 'Dashboard',
    type: 'section',
    children: [
      {
        name: 'Home',
        type: 'item',
        icon: <ImHome3 />,
        link: '/home',
      },
      {
        name: 'Hasil Pasien',
        type: 'item',
        icon: <BsPersonLinesFill />,
        link: '/hasil-pasien',
      },
      {
        name: 'Change Password',
        type: 'item',
        icon: <FaUserLock />,
        link: '/change-password',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
