import {
  SET_LIST_HOME_ACTIVE_PAGINATION,
  SET_LIST_HOME_KEYWORD,
  SET_LIST_HOME_DATA,
  SET_LIST_HOME_PAGINATION,
  SET_HOME_SELECTED,
  SET_HOME_SELECTED_FILE,
  SET_HOME_DEFAULT_STATE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  HomeData: [],
  selectedData: null,
  selectedFile: [],
  activePaginate: 1,
  activeLimit: 10,
  pagination: {
    current_page: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
    from: 0,
  },
  keyword: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LIST_HOME_DATA: {
      return {
        ...state,
        // InformasiData: [...state.InformasiData, ...action.payload],
        HomeData: action.payload,
      };
    }
    case SET_LIST_HOME_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case SET_LIST_HOME_ACTIVE_PAGINATION: {
      return {
        ...state,
        activePaginate: action.payload,
      };
    }
    case SET_LIST_HOME_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    case SET_HOME_SELECTED: {
      return {
        ...state,
        selectedData: action.payload,
      };
    }
    case SET_HOME_SELECTED_FILE: {
      return {
        ...state,
        selectedFile: action.payload,
      };
    }
    case SET_HOME_DEFAULT_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
