import React, { Fragment } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { FaClinicMedical } from 'react-icons/fa';
import { BsFillPersonBadgeFill, BsAsterisk } from 'react-icons/bs';
import { GoChecklist } from 'react-icons/go';
import { BiSearch } from 'react-icons/bi';
import TableAddPasien from './TableAddPasien';
import LoadData from '../../../services/rujukan';
import { useDispatch, useSelector } from 'react-redux';
import { setRujukanKeyword, setRujukanDefaultState } from '../../../redux/actions';
import { AsyncPaginate } from 'react-select-async-paginate';
import Select from 'react-select';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router';
import TextField from '@material-ui/core/TextField';

const Panggilan = [
  { value: 'Ibu', label: 'Ibu' },
  { value: 'Bapak', label: 'Bapak' },
  { value: 'Nyonya', label: 'Nyonya' },
];
const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'data pasien rujukan', link: '/data-pasien-rujukan' },
  { label: 'tambah data pasien rujukan', isActive: true },
];
const AddPasienRujuk = () => {
  const dispatch = useDispatch();
  const [keywordLocal, setKeyword] = React.useState('');
  const [selectedPrice, setSelectedPrice] = React.useState([]);
  const [valueList, setValueList] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [nikdata, setNik] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [alamat, setAlamat] = React.useState('');
  const [nama, setNama] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [kota, setKota] = React.useState('');
  const [telp, setTelp] = React.useState('');
  const [dateSpace, setDateSpace] = React.useState('');
  const [jk, setJk] = React.useState('');
  const [klinik, setKlinik] = React.useState('');
  const { titledata } = useSelector(state => state.rujukan);
  const [selectedData, setSelectedData] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  React.useEffect(() => {
    dispatch(setRujukanDefaultState());
    dispatch(LoadData.getKlinikTitle());
  }, [dispatch]);

  const handleKeyword = () => {
    dispatch(setRujukanKeyword(keywordLocal));
  };

  const handleChange = value => {
    setValueList(value);
    setKlinik(value.data);
  };

  const handleChangeSelect = value => {
    setTitle(value);
    setJk(value.gender);
  };

  const handleSave = async value => {
    const obj = {
      kode_cabang: valueList.value,
      nama_cabang: valueList.label,
      tgl_lahir: moment(dob).format('Y-MM-DD'),
      tgl_sampling: moment(dateSpace).format('Y-MM-DD HH:mm'),
      jenis_kelamin: jk,
      alamat: alamat,
      kota: kota,
      nik: nikdata,
      email: email,
      no_hp: telp,
      title: title.value,
      nama: nama,
      data: selectedData,
    };
    const result = await dispatch(LoadData.saveData(obj));
    if (result.success) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        history.push('/data-pasien-rujukan');
      });
    }
  };

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    let filteredOptions;
    let responseJSON;

    const response = await LoadData.getKlinikList(searchQuery, page);
    responseJSON = await response.json();
    filteredOptions = responseJSON.getKlinik.data.map(item => ({
      data: item,
      value: item.kode,
      label: `${item.kode}-${item.nama}`,
    }));
    const { last_page, current_page } = responseJSON.getKlinik;
    return {
      options: filteredOptions,
      hasMore: current_page === last_page ? false : true,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };
  return (
    <Fragment>
      <PageContainer heading={'Tambah Data Pasien Rujukan'} breadcrumbs={breadcrumbs} />
      <div className="wrap-pasien-rujukan">
        <h3>Tambah Data Baru</h3>
        <div className="line " />
        <div className="subtitle-card-add">
          <p>Informasi Klinik</p>
          <FaClinicMedical />
        </div>
        <div className="wrap-input-form-asyn">
          <div className="wrap-input-add-asyn">
            <p>
              Klinik Rujukan
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            {/* <input type="text" placeholder="Klinik Rujukan" /> */}
            <AsyncPaginate
              value={valueList}
              loadOptions={loadOptions}
              onChange={handleChange}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
              placeholder="cari id klinik"
              additional={{
                page: 1,
              }}
            />
          </div>
        </div>
        <div className="line " />
        <div className="subtitle-card-add">
          <p>Informasi Pasien</p>
          <BsFillPersonBadgeFill />
        </div>

        <div className="wrap-input-form">
          <div className="wrap-input-add-panggilan">
            <p>
              Title
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            {/* <input type="text" placeholder="Title" /> */}
            <Select options={titledata} onChange={handleChangeSelect} />
          </div>
          <div className="wrap-input-add">
            <p>
              Nama
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            <input type="text" placeholder="Nama " onChange={e => setNama(e.target.value)} />
          </div>
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add">
            <p>Nik</p>
            <input type="text" placeholder="Nik" onChange={e => setNik(e.target.value)} />
          </div>
          <div className="wrap-input-add">
            <p>Email</p>
            <input type="email" placeholder="Email " name="email" onChange={e => setEmail(e.target.value)} />
          </div>
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add">
            <p>
              Tgl Lahir
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            <input type="Date" placeholder="Tanggal" onChange={e => setDob(e.target.value)} />
          </div>
          <div className="wrap-input-add">
            <p>
              Jenis Kelamin
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            <input type="text" placeholder="Jenis Kelamin" disabled value={jk} />
          </div>
        </div>
        <div className="wrap-input-add-textarea">
          <p>Alamat</p>
          <textarea placeholder="alamat" onChange={e => setAlamat(e.target.value)} />
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add">
            <p>Kota</p>
            <input type="text" placeholder="Kota" onChange={e => setKota(e.target.value)} />
          </div>
          <div className="wrap-input-add">
            <p>No Telepon</p>
            <input type="number" placeholder="No Telepon" onChange={e => setTelp(e.target.value)} />
          </div>
        </div>
        <div className="wrap-input-form">
          <div className="wrap-input-add-spesiment">
            <p>
              Tgl Pengambilan Darah / spesiment
              <span style={{ paddingLeft: 6 }}>
                <BsAsterisk color="red" size={'10px'} />
              </span>
            </p>
            {/* <input type="Date" placeholder="input tanggal" onChange={e => setDateSpace(e.target.value)} /> */}
            <TextField
              id="datetime-local"
              type="datetime-local"
              onChange={e => setDateSpace(e.target.value)}
              InputLabelProps={{
                shrink: false,
              }}
              className="date-custome"
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <div className="wrap-input-add">
            <p></p>
            <span style={{ color: 'red', fontSize: 16, fontWeight: 500 }}>
              <BsAsterisk color="red" size={'10px'} />) tanda ini wajib untuk di isi
            </span>
          </div>
        </div>

        <div className="line " />

        <div className="subtitle-card-add">
          <p>Jenis Pemeriksaan</p>
          <GoChecklist />
        </div>
        <div className="search-style-periksa">
          <input type="text" placeholder="Search By" onKeyPress={handleKeyword} onChange={e => setKeyword(e.target.value)} />
          <button className="search-rujukan" onClick={handleKeyword}>
            <BiSearch />
          </button>
        </div>
        <div className="container-table-add">
          <TableAddPasien
            id={valueList.value}
            setSelectedPrice={e => setSelectedPrice(e)}
            selectedPrice={selectedPrice}
            selectedData={selectedData}
            setSelectedData={e => setSelectedData(e)}
          />
          <div className="line " />
          <div className="btn-submit">
            <div className="total-add">
              <p>Total</p>
              <p>{selectedPrice.reduce((partialSum, a) => partialSum + parseInt(a), 0)}</p>
            </div>
            <button onClick={handleSave}>save</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddPasienRujuk;

const options = [];
for (let i = 0; i < 50; ++i) {
  options.push({
    value: i + 1,
    label: `Option ${i + 1}`,
  });
}

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const loadOptions = async (search, prevOptions) => {
  await sleep(1000);

  let filteredOptions;
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower));
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 10);

  return {
    options: slicedOptions,
    hasMore,
  };
};
