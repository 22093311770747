import React, { Fragment } from 'react';
import { HiOutlineLogout } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import authLoad from '../../../../services/auth/jwt';

const Logout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authLoad.onLogout());
  };

  return (
    <Fragment>
      <div onClick={handleLogout} className="wrap-logout">
        <HiOutlineLogout size={28} />
        <p>Logout</p>
      </div>
    </Fragment>
  );
};

export default Logout;
