export function formatRupiah(value, prefix) {
  if (value) {
    var angka = value.toString().split('.');
    var number_string = angka[0].replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      let separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : '';
  }

  return value;
}

export function formatPercent(value) {
  if (value) {
    return (
      parseInt(value)
        .toFixed()
        .toString() + '%'
    );
  }
  return value;
}
