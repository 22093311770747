import {
  SET_LIST_PASIEN_ACTIVE_PAGINATION,
  SET_LIST_PASIEN_KEYWORD,
  SET_LIST_PASIEN_DATA,
  SET_LIST_PASIEN_PAGINATION,
  SET_PASIEN_SELECTED,
  SET_PASIEN_SELECTED_FILE,
  SET_PASIEN_DEFAULT_STATE,
  SET_PASIEN_STARTDATE,
  SET_PASIEN_ENDDATE,
} from '../../@jumbo/constants/ActionTypes';

export const getPASIENList = data => {
  return dispatch => {
    dispatch({
      type: SET_LIST_PASIEN_DATA,
      payload: data,
    });
  };
};

export const setPASIENPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_LIST_PASIEN_PAGINATION,
      payload: pagination,
    });
  };
};

export const setActivePASIENPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_LIST_PASIEN_ACTIVE_PAGINATION,
      payload: pagination,
    });
  };
};

export const setKeywordPASIEN = keyword => {
  return dispatch => {
    dispatch({
      type: SET_LIST_PASIEN_KEYWORD,
      payload: keyword,
    });
  };
};

// export const setSelectedData = data => {
//   return dispatch => {
//     dispatch({
//       type: SET_PASIEN_SELECTED,
//       payload: data,
//     });
//   };
// };

// export const setSelectedFile = data => {
//   return dispatch => {
//     dispatch({
//       type: SET_PASIEN_SELECTED_FILE,
//       payload: data,
//     });
//   };
// };

export const setPASIENDefault = () => {
  return dispatch => {
    dispatch({
      type: SET_PASIEN_DEFAULT_STATE,
      payload: {},
    });
  };
};

export const setStartDatePasien = data => {
  return dispatch => {
    dispatch({
      type: SET_PASIEN_STARTDATE,
      payload: data,
    });
  };
};

export const setEndDatePasien = data => {
  return dispatch => {
    dispatch({
      type: SET_PASIEN_ENDDATE,
      payload: data,
    });
  };
};
