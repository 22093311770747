import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paginations from '@material-ui/lab/Pagination';
import { AiOutlineFileText } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import './Hasilpasien.css';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import pasienLoad from '../../../services/pasien';
import url from '../../../@jumbo/constants/UrlConstant';
import moment from 'moment';
import {
  setActivePASIENPagination,
  setPASIENDefault,
  setKeywordPASIEN,
  setStartDatePasien,
  setEndDatePasien,
} from '../../../redux/actions';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F6F8FA',
    color: '#7B7B7B',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ul: {
    '& .MuiPaginationItem-page': {
      color: '#131313',
      background: '#F7F7FA',
      border: 'none',
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#026EB8',
    },
  },
});

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'hasil-pasien', isActive: true },
];
const HasilPasien = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const HandlePagination = (event, current_page) => {
    dispatch(setActivePASIENPagination(current_page));
  };

  const {
    authUser: { role },
  } = useSelector(({ auth }) => auth);

  const { PASIENData, pagination, activePaginate, keyword, startDate, endDate } = useSelector(({ pasien }) => pasien);
  const [keywordLocal, setKeyword] = React.useState('');
  const [startDateLocal, setStartDate] = React.useState('');
  const [endDateLocal, setEndDate] = React.useState('');

  React.useEffect(() => {
    dispatch(pasienLoad.getHasilBaru());
  }, [dispatch, activePaginate, keyword, startDate, endDate]);

  React.useEffect(() => {
    return () => {
      dispatch(setPASIENDefault());
    };
  }, [dispatch]);

  const handleKeyword = () => {
    dispatch(setKeywordPASIEN(keywordLocal));
  };

  const resetData = () => {
    document.getElementById('start').value = '';
    document.getElementById('end').value = '';
    window.location.reload();
  };

  const handleDate = () => {
    if (startDateLocal) {
      dispatch(setStartDatePasien(moment(startDateLocal).format('Y-MM-DD')));
    } else {
      dispatch(setStartDatePasien(''));
    }

    if (endDateLocal) {
      dispatch(setEndDatePasien(moment(endDateLocal).format('Y-MM-DD')));
    } else {
      dispatch(setEndDatePasien(''));
    }
  };

  const handleFile = (file, nolab) => {
    dispatch(pasienLoad.updateStatusFile(file, nolab));
  };
  return (
    <Fragment>
      <PageContainer heading="Hasil Pasien" breadcrumbs={breadcrumbs} />
      <div className="table-wrap-hasil-terbaru">
        <h3>Data Hasil Terbaru</h3>
        {role === 'instansi' && (
          <div className="head-search">
            <div className="wrap-date">
              <p>Cari Data :</p>
              <input type="date" name="start" id="start" onChange={e => setStartDate(e.target.value)} />
              <p>-</p>
              <input type="date" name="end" id="end" onChange={e => setEndDate(e.target.value)} />
              <button onClick={handleDate}>Cari</button>
              <button onClick={resetData}>Reset</button>
            </div>
            <div className="search-style">
              <input
                type="text"
                placeholder="Search By"
                onKeyPress={handleKeyword}
                onChange={e => setKeyword(e.target.value)}
              />
              <button onClick={handleKeyword}>
                <BiSearch />
              </button>
            </div>
          </div>
        )}

        {role !== 'instansi' && (
          <div className="head-search-single">
            <div className="search-style">
              <input type="text" placeholder="Search By" onChange={e => setKeyword(e.target.value)} />
              <button onClick={handleKeyword}>
                <BiSearch />
              </button>
            </div>
          </div>
        )}
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No Reg</StyledTableCell>
                <StyledTableCell align="left">No Lab</StyledTableCell>
                <StyledTableCell align="left">Nama</StyledTableCell>
                <StyledTableCell align="left">Tanggal Periksa</StyledTableCell>
                {role === 'personal' ? (
                  <StyledTableCell align="left">Dokter</StyledTableCell>
                ) :  <StyledTableCell align="left">Alamat</StyledTableCell>}
               
                <StyledTableCell align="left">Hasil</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PASIENData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.noreg}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.nolab}</StyledTableCell>
                  <StyledTableCell align="left">{row.nama}</StyledTableCell>
                  <StyledTableCell align="left">{row.tanggal_periksa}</StyledTableCell>
                  <StyledTableCell align="left">{row.alamat}</StyledTableCell>
                  <StyledTableCell align="left">
                    {/* <a target="_blank" href={`${url.baseContent}${row.file}`} className="hasil-table">
                      <AiOutlineFileText />
                      <p>{row.keterangan}</p>
                    </a> */}
                    <div
                      className={row.status == 'read' ? 'hasil-table-read' : 'hasil-table'}
                      onClick={() => handleFile(row.file, row.nolab)}>
                      <p>{row.keterangan}</p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.status}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="style-pagination">
        <Paginations
          className={classes.ul}
          count={pagination.last_page}
          variant="outlined"
          shape="rounded"
          page={activePaginate}
          onChange={HandlePagination}
        />
      </div>
    </Fragment>
  );
};

export default HasilPasien;
