import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';

const updateLoad = {
  updatePassword: request => {
    return (dispatch, getState) => {
      const {
        authUser: { role },
      } = getState().auth;
      dispatch(fetchStart());
      let endpoin;
      if (role === 'personal') {
        endpoin = `${url.personal.update}`;
      } else if (role === 'instansi') {
        endpoin = `${url.instansi.update}`;
      } else {
        endpoin = `${url.dokter.update}`;
      }
      return axios
        .post(endpoin, request)
        .then(({ data, status }) => {
          if (status === 200 && data.success) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            if (data.message.email) {
              dispatch(fetchError(data.message.email));
            } else if (data.message.password) {
              dispatch(fetchError(data.message.password));
            }
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
};

export default updateLoad;
