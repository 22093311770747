import {
  SET_LIST_RUJUKAN_ACTIVE_PAGINATION,
  SET_LIST_RUJUKAN_KEYWORD,
  SET_LIST_RUJUKAN_DATA,
  SET_LIST_RUJUKAN_PAGINATION,
  SET_RUJUKAN_SELECTED,
  SET_RUJUKAN_SELECTED_FILE,
  SET_RUJUKAN_DEFAULT_STATE,
  SET_RUJUKAN_STARTDATE,
  SET_RUJUKAN_ENDDATE,
  SET_LIST_RUJUKAN_LIMIT,
  SET_LIST_RUJUKAN_TITLE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  rujukanData: [],
  activePaginate: 1,
  pagination: {
    current_page: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
    from: 0,
  },
  keyword: '',
  startDate: '',
  endDate: '',
  limit: 10,
  titledata: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LIST_RUJUKAN_DATA: {
      return {
        ...state,
        rujukanData: action.payload,
      };
    }
    case SET_LIST_RUJUKAN_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case SET_LIST_RUJUKAN_ACTIVE_PAGINATION: {
      return {
        ...state,
        activePaginate: action.payload,
      };
    }
    case SET_LIST_RUJUKAN_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    case SET_LIST_RUJUKAN_LIMIT: {
      return {
        ...state,
        limit: action.payload,
      };
    }
    case SET_RUJUKAN_STARTDATE: {
      return {
        ...state,
        startDate: action.payload,
      };
    }
    case SET_RUJUKAN_ENDDATE: {
      return {
        ...state,
        endDate: action.payload,
      };
    }
    case SET_RUJUKAN_DEFAULT_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    case SET_LIST_RUJUKAN_TITLE: {
      return {
        ...state,
        titledata: action.payload,
      };
    }
    default:
      return state;
  }
};
